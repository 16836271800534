<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-card-title class="mb-1">
          Cajero Automatico
        </b-card-title>
        <b-card-text class="mb-2">
          Acerca el ticket al escaner para cobrarlo
        </b-card-text>

        <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
          >
            <b-form-group
            >
              <b-form-input
                  type="password"
                  v-model="paymentAuthorizationId"
                  ref="focusMe"
                  :disabled="disableInput"
              />
            </b-form-group>
          </b-form>

      </b-card>
      <br>
      <b-alert
        :variant=alertStatus
        show
        v-if="showErrorAlert"
      >
        <div class="alert-body font-medium-2">
          <p>{{errorMsg}}</p>
        </div>
      </b-alert>
    </div>
  </div>
</template>
<script>
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BAlert,
} from 'bootstrap-vue'
import router from "@/router";

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BAlert,
  },
  data() {
    return {
      paymentAuthorizationId: '',
      errorMsg: '',
      alertStatus: 'danger',
      disableInput: false,
      showErrorAlert: false,
      user: JSON.parse(localStorage.getItem("user")),

      posDetails: {
          posId: undefined,
          userId: undefined,
          companyId: undefined,
          cashierApiUrl: undefined,
          cashierId: undefined,
          expirationDaysOfPaymentAuthorization: undefined
      },
    }
  },
  created() {
      this.getUserInfoApi();
      this.intervalPosDetails = setInterval(this.getUserInfoApi, 20000);
  },
  beforeRouteLeave(to, from, next) {
      // Evitar que se salgan de la pantalla y perder datos
      const answer = window.confirm(
          "¿Seguro que desea salir? Los cambios no guardados se perderán."
      );
      if (answer) {
          // if (this.timer) clearInterval(this.timer);
          if (this.intervalPosDetails) clearInterval(this.intervalPosDetails);
          next();
      } else {
          next(false);
      }
  },
  mounted () {
    this.$nextTick(() => this.$refs.focusMe.focus())
  },
  methods: {
  validationForm() {

    this.disableInput = true;

    this.$axios.post(this.posDetails.cashierApiUrl + 'dispense', {
        paymentAuthorizationId: this.paymentAuthorizationId,
        cashierId: this.posDetails.cashierId
    }).then(res => {
        this.setFormResponse(true, res.data.data);
    }).catch(error => {
      this.setFormResponse(false, error?.response?.data.data ?? 'Wait some minutes, the cashier is starting');
    }).finally(() => {
      setTimeout(() => this.showErrorAlert = false, 2000);
      this.paymentAuthorizationId = '';
      this.disableInput = false;
      this.$nextTick(() => this.$refs.focusMe.focus())
    });
  },
  getUserInfoApi(){
      this.$axios.post(this.$services + "pos/getUserInfoApi", {
          userId: this.user.userId,
      })
          .then((res) => {
              if (!res.data.error) {
                  this.posDetails = res.data;
                  this.removeDataFromLocalStorage("posDetails")
                  this.addDataToLocalStorage("posDetails", this.posDetails)
              }
          })
          .catch((error) => {
              this.posDetails = this.getDataFromLocalStorage("posDetails")
          }).finally(()=> {})
  },
  removeDataFromLocalStorage(key){
      localStorage.removeItem(key);
  },
  addDataToLocalStorage(key, data){
      localStorage.setItem(key, JSON.stringify(data));
  },
  getDataFromLocalStorage(key){
      return (JSON.parse(localStorage.getItem(key)) ?? []);
  },
  setFormResponse(dispensed, msg) {
    this.showErrorAlert = true;
    this.alertStatus = dispensed ? 'success' : 'danger';
    this.errorMsg = msg ?? 'Problemas con la conexion, contacte al administrador.';
  }
},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
